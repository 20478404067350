let timers = [];
export function debounce ( fn, delay, timerKey ) {
    if( !timerKey ){
        throw 'timerKey missing!';
    }
    return function () {
        if( timers[ timerKey ] ) clearTimeout( timers[ timerKey ] )
        timers[ timerKey ] = setTimeout(function () {
            clearTimeout( timers[ timerKey ] )
            fn()
        }, delay )
    }();
}